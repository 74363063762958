import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import classnames from 'classnames';

const ImageComponent = ({
  data,
  className,
  deskProps,
  mobileProps,
  ...props
}) => {
  if (!data || !data?.image) return null;
  const hasMobile = data?.hasImageMobile && data?.imageMobile;
  const alt = data?.alt || '';

  return (
    <>
      {hasMobile && (
        <Image
          {...data?.imageMobile}
          alt={alt}
          className={classnames('d-md-none', className)}
          {...mobileProps}
          {...props}
        />
      )}
      <Image
        {...data?.image}
        alt={alt}
        className={classnames('d-md-inline', className, {
          'd-none': hasMobile,
        })}
        {...deskProps}
        {...props}
      />
    </>
  );
};

export default ImageComponent;
