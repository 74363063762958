import React, { useState, useEffect } from 'react';

import BffWhiteLogo from '../../assets/images/BFF_LOGO.svg';

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';
import { useMediaQueries } from '@react-hook/media-query';

import AnchorLink from '../ui/anchor-link';

const MobileNavBarBff = ({ items, logo, legalItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledInverse] = useState(false);

  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: 'only screen and (max-width: 580px)',
  });

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggle = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  const hideCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <section
      className={classnames('d-block d-sm-none mobilenav', {
        scrolled: scrolled,
        'is-open': isOpen,
        inverse: scrolledInverse,
      })}
    >
      <Container className="">
        <Navbar
          light={!isMobile}
          dark={isMobile}
          className="main-navbar mt-2 px-0"
          sticky="top"
          expand="lg"
          container={false}
        >
          <NavbarBrand href="/" title="Home" className="mr-auto">
            <figure className="logo-container m-0">
              <img src={BffWhiteLogo} />
            </figure>
          </NavbarBrand>
          <NavbarToggler
            onClick={toggle}
            className={classnames('mobile-navbar--toggler p-0 w-0 border-0')}
          />

          <Collapse isOpen={isOpen} navbar className="collapse-container">
            <div
              className="blocker d-block d-sm-none"
              onClick={hideCollapse}
            ></div>

            <Nav className="main-navbar--nav mt-4 mt-md-0 ps-4 ps-md-0" navbar>
              {items.map((item) => (
                <NavItem key={`nav-item-${item?._key}`}>
                  <i className="icon-ic_famousstar_active" />
                  <AnchorLink
                    className="nav-link d-inline-block text-uppercase ps-2 mr-lg-5"
                    key={item?._key}
                    type={item?.type}
                    link={item?.link}
                    text={item?.title}
                    target={item?.target}
                    onClick={toggle}
                  />
                </NavItem>
              ))}
              <hr className="main-navbar__hr-line d-block d-sm-none" />
              <div className="legal-links-container">
                {legalItems.map((item) => (
                  <NavItem key={`nav-item-${item?._key}`}>
                    <AnchorLink
                      className="nav-link--legal d-inline-block ps-2 mr-lg-5 d-block d-sm-none"
                      key={item?._key}
                      type={item?.type}
                      link={item?.link}
                      text={item?.title}
                      target={item?.target}
                    />
                  </NavItem>
                ))}
              </div>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </section>
  );
};

export default MobileNavBarBff;
