import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Image from '../ui/image';
import InstragramLogo from '../../assets/images/Instagram.svg';
import FacebookLogo from '../../assets/images/facebook.svg';

import { Container, Row, Col } from 'reactstrap';
import ImageComponent from '../ui/image';

const Footer = () => {
  const {
    sanityHome: {
      tabs: {
        footer: { facebook, instagram, logo, links, foundationLogos },
      },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      sanityHome(slug: { current: { eq: "index" } }) {
        tabs {
          footer {
            _key
            _type
            facebook
            instagram
            foundationLogos {
              ...imageComponent
            }
            logo {
              ...imageComponent
            }
            links {
              title
              link
              _type
              _key
            }
          }
        }
      }
    }
  `);
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col className="footer__logo-row" xl={2} xs={4}>
            <figure className="footer__logo-container">
              <Image height="32px" data={logo} />
            </figure>
          </Col>
          <Col className="footer__icons-row" xl={4} xs={4}>
            <Row className="footer__icons-title">
              <span>Síguenos</span>
            </Row>
            <Row className="footer__icons-container">
              <Link href={instagram} target={instagram}>
                <img src={InstragramLogo} alt="" className="footer__icons" />
              </Link>
              <Link href={facebook} target={facebook}>
                <img src={FacebookLogo} alt="" className="footer__icons" />
              </Link>
            </Row>
          </Col>
          <Col xl={3} xs={12}>
            <span className="footer__link-title">Transparencia</span>
            <Link to={links[0]?.link} className="footer__links">
              {links[0]?.title}
            </Link>
            <Link to={links[1]?.link} className="footer__links">
              {links[1]?.title}
            </Link>
          </Col>
          <Col xl={3} xs={12}>
            <span className="footer__link-title">Talento</span>
            <Link to={links[3]?.link} className="footer__links">
              {links[3]?.title}
            </Link>
            <Link to={links[2]?.link} className="footer__links">
              {links[2]?.title}
            </Link>
          </Col>
        </Row>
        <Row className="footer__copy-container flex-lg-row-reverse align-items-lg-end mt-4">
          <Col xl={6} xs={12}>
            {foundationLogos.length ? (
              <>
                <span className="footer__link-title mt-0 mb-3">
                  Apoyamos a:
                </span>
                <Row>
                  {foundationLogos.map((logo) => (
                    <Col xs={3} md={2} xl={3}>
                      <ImageComponent data={logo} className="w-100" />
                    </Col>
                  ))}
                </Row>
              </>
            ) : null}
          </Col>

          <Col xl={6} xs={12} className="footer__copy-text">
            &copy; Copyright {new Date().getFullYear()} Red Valley. All rights
            reserved.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
