import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MobileNavBarBff from './mobileNavBar';
import SmartBanner from 'react-smartbanner';

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';
import { useMediaQueries } from '@react-hook/media-query';

import AnchorLink from '../ui/anchor-link';
import Image from '../ui/image';

const NavbarBFF = () => {
  const {
    sanityHome: {
      tabs: {
        navbar: { items, logo, legalItems },
      },
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      sanityHome(slug: { current: { eq: "index" } }) {
        tabs {
          navbar {
            items {
              title
              link
              type
              _key
            }
            logo {
              ...imageComponent
            }
            legalItems {
              _key
              link
              title
              type
            }
          }
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledInverse] = useState(false);

  const {
    matches: { isMobile = false },
  } = useMediaQueries({
    isMobile: 'only screen and (max-width: 580px)',
  });

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggle = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  const hideCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      {isMobile ? (
        <section>
          <SmartBanner
            title="Best Friend Famoso"
            author="Infinito Capital Group"
            daysHidden={15}
            daysReminder={90}
            position="top"
            button="Ver"
            storeText={{ ios: 'En App Store', android: 'En Google Play' }}
            price={{
              ios: 'GRATIS',
              android: 'GRATIS',
            }}
            url={{
              ios: 'https://apps.apple.com/us/app/best-friend-famoso/id1605031926',
              android:
                'https://play.google.com/store/apps/details?id=com.bestfriendfamoso.people',
            }}
          />
        </section>
      ) : null}
      <header
        className={classnames('header-container', {
          scrolled: scrolled,
          'is-open': isOpen,
          inverse: scrolledInverse,
        })}
      >
        <Container>
          <Navbar
            light={!isMobile}
            dark={isMobile}
            className="main-navbar mt-2 px-0"
            sticky="top"
            expand="lg"
            container={false}
          >
            <NavbarBrand
              href="/"
              title="Home"
              className="mr-auto main-navbar--brand"
            >
              <figure className="logo-container m-0">
                <Image height="32px" data={logo} className="logo" />
              </figure>
            </NavbarBrand>
            <NavbarToggler
              onClick={toggle}
              className={classnames('main-navbar--toggler p-0 w-0 border-0')}
            />

            <Collapse isOpen={isOpen} navbar className="collapse-container">
              <div
                className="blocker d-block d-sm-none"
                onClick={hideCollapse}
              ></div>
              <Nav
                className="main-navbar--nav mt-4 mt-md-0 ps-4 ps-md-0"
                navbar
              >
                {items.map((item) => (
                  <NavItem key={`nav-item-${item?._key}`}>
                    <i className="icon-ic_famousstar_active" />
                    <AnchorLink
                      className="nav-link d-inline-block text-uppercase ps-2 mr-lg-5"
                      key={item?._key}
                      type={item?.type}
                      link={item?.link}
                      text={item?.title}
                      target={item?.target}
                      onClick={toggle}
                    />
                  </NavItem>
                ))}
                <hr className="main-navbar__hr-line d-block d-sm-none" />
                <div className="legal-links-container">
                  {legalItems.map((item) => (
                    <NavItem key={`nav-item-${item?._key}`}>
                      <AnchorLink
                        className="nav-link--legal d-inline-block ps-2 mr-lg-5 d-block d-sm-none"
                        key={item?._key}
                        type={item?.type}
                        link={item?.link}
                        text={item?.title}
                        target={item?.target}
                      />
                    </NavItem>
                  ))}
                </div>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </header>
      <MobileNavBarBff items={items} logo={logo} legalItems={legalItems} />
    </>
  );
};

export default NavbarBFF;
